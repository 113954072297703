import { Table } from "reactstrap";
import './Globals.css'
import { useDispatch } from "react-redux";
import { getIECFormDataAPICall } from "../screens/api/iecActivityAPIs";
import { handleUpdatePageSlice } from "../redux/slices/globalSlice";

const TableComponent = ({ data, tableHeadersList }: any) => {
    const dispatch: any = useDispatch();
    const handleViewForm = (iecId: string) => {
        dispatch(getIECFormDataAPICall('goutham', iecId));
        dispatch(handleUpdatePageSlice(true))
    }

    const keys = data.data?.length > 0 ? Object.keys(data.data[0]) : [];
    return (
        <div style={{ paddingBottom: '40px' }}>
            <Table striped hover>
                <thead>
                    <tr className="align-center">
                        {tableHeadersList.map((key: any) => (
                            <th style={{ textAlign: "center" }} key={key}>{key}</th>
                        ))}
                    </tr>
                </thead>
                {data?.statusCode === 1 ? <tbody>
                    {data?.data?.map((row: any, rowIndex: any) => (
                        <tr key={rowIndex}>
                            {keys.map((key) => {
                                return (
                                    <td style={{ background: '#ECF1F4', textAlign: "center" }} key={key}>{
                                        row[key] === row.iecDate ? row.iecDate.split("T")[0].split("-").reverse().join("-") : row[key]
                                    }</td>
                                )
                            })}
                            <td style={{ background: '#ECF1F4' }}
                                className="view-form"
                                onClick={() => handleViewForm(row.iecId ?? '')}>
                                View Form
                            </td>
                        </tr>
                    ))}
                </tbody> : ""}
            </Table>
        </div>
    );
};
export default TableComponent;