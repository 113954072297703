
import { createSlice } from "@reduxjs/toolkit";

interface GlobalSliceProps {
  isLoading: boolean;
  error: boolean;
  updatePageStore: boolean;
  storeIECPaginationData: Object;
}

const initialState: GlobalSliceProps = {
  isLoading: false,
  error: false,
  updatePageStore: false,
  storeIECPaginationData: {
    offset: 0,
    limit: 25,
    selectedLimit: 25,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Method to update page render
    handleUpdatePageSlice(state, action) {
      state.updatePageStore = action.payload;
    },
    handleIECPaginationDataSlice(state, action) {
      state.storeIECPaginationData = action.payload;
    },
  },
});
// export const globalSelector =(state: any) => state.global;

export const { startLoading, hasError, handleUpdatePageSlice, handleIECPaginationDataSlice } = globalSlice.actions;

export default globalSlice.reducer;
