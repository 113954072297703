import { toast } from "react-toastify";
import { handleError, handleFetchActivitiesData, handleFetchDistrictsData, handleFetchExcelData, handleFetchIecFormData, handleFetchIecInfoData, handleFetchImageData, handleFetchSeasonData, handleFetchStatesData, handleFetchTehsilData, handleStartLoading, handleStopLoading, handleSurveyorData } from "../../redux/slices/filterSlice";
import { method, paths } from "../../services/ServiceConstants";
import ServicesManagerAPICall from "../../services/ServicesManagerAPICall";

/** Method to fetch Season Data */
export const getSeasonsDataAPICall = () => {

  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_SEASONS_DATA_ENDPOINT,
      ).then(async response => {
        if (response.statusCode === 1) {
          dispatch(handleFetchSeasonData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch States Data */
export const getStatesDataAPICall = () => {
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_ALL_STATES_ENDPOINT,
      ).then(async response => {
        console.log('response', response);
        if (response.statusCode === 1) {
          dispatch(handleFetchStatesData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch Districts Data */
export const getDistrictsDataAPICall = (username: string, sid: number | string) => {
  const body: Object = {
    sid: sid
  };
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_ALL_DISTRICTS_ENDPOINT,
        body,
      ).then(async response => {
        console.log('response', response);
        if (response.statusCode === 1) {
          dispatch(handleFetchDistrictsData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch Tehsils Data */
export const getTehsilDataAPICall = (username: string, sid: any, did: number | string) => {
  const body: Object = {
    sid: sid,
    did: did,
  };
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_ALL_TEHSIL_ENDPOINT,
        body,
      ).then(async response => {
        console.log('response', response);
        if (response.statusCode === 1) {
          dispatch(handleFetchTehsilData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};


/** Method to fetch Activities Data */
export const getActivityDataAPICall = () => {
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_ACTIVITIES_ENDPOINT,
      ).then(async response => {
        console.log('response', response);
        if (response.statusCode === 1) {
          dispatch(handleFetchActivitiesData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch IEC info Data */
export const getIECInfoData = (username: string, limit: number, offset: number, stateId?: any, districtId?: any, blockId?: any, seasonId?: any, year?: any, fromDate?: any, toDate?: any, mobileNumber?: any, activityId?: any) => {
  const body = {
    limit: limit,
    offset: offset,
    stateId: stateId,
    districtId: districtId,
    blockId: blockId,
    seasonId: seasonId,
    year: year,
    fromDate: fromDate,
    toDate: toDate,
    mobileNumber: mobileNumber,
    activityId: activityId
  };
  if (!stateId) delete body.stateId;
  if (!districtId) delete body.districtId;
  if (!blockId) delete body.blockId;
  if (!seasonId) delete body.seasonId;
  if (!year) delete body.year;
  if (!fromDate || fromDate === 'NaN-aN-aN') delete body.fromDate;
  if (!toDate || toDate === 'NaN-aN-aN') delete body.toDate;
  if (!mobileNumber) delete body.mobileNumber;
  if (!activityId) delete body.activityId;

  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_IEC_INFO_ENDPOINT,
        body,
      ).then(async response => {
        if (response.statusCode === 1) {
          dispatch(handleFetchIecInfoData(response));
        }
        else {
          dispatch(handleFetchIecInfoData(response));
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
}

/** Method to fetch Activities Data */
export const getIECFormDataAPICall = (username: string, iecId: string) => {
  const body: Object = {
    iecId: iecId,
  };
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_FORM_DATA_ENDPOINT,
        body,
      ).then(async response => {
        if (response.statusCode === 1) {
          dispatch(handleFetchIecFormData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch Activities Data */
export const getIECImagesAPICall = (username: string, iecId: string, imagePath: []) => {
  const body: Object = {
    iecId: iecId,
    imagePath: imagePath
  };

  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_Images_ENDPOINT,
        body,
      ).then(async response => {
        if (response.statusCode === 1) {
          dispatch(handleFetchImageData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch Activities Data */
interface ExcelParams {
  username: string;
  stateId?: any,
  districtId?: any,
  blockId?: any,
  seasonId?: any,
  year?: any,
  fromDate?: any,
  toDate?: any,
  mobileNumber?: any,
  activityId?: any
}
export const getExcelDownload = ({ stateId, districtId, blockId, seasonId, year, fromDate, toDate, mobileNumber, activityId }: ExcelParams) => {
  const body: any = {
    stateId: stateId,
    districtId: districtId,
    blockId: blockId,
    seasonId: seasonId,
    year: year,
    fromDate: fromDate ?? -1,
    toDate: toDate ?? -1,
    mobileNumber: mobileNumber,
    activityId: activityId
  };
  if (!stateId) delete body.stateId;
  if (!districtId) delete body.districtId;
  if (!blockId) delete body.blockId;
  if (!seasonId) delete body.seasonId;
  if (!year) delete body.year;
  if (!fromDate || fromDate === 'NaN-aN-aN') delete body.fromDate;
  if (!toDate || toDate === 'NaN-aN-aN') delete body.toDate;
  if (!mobileNumber) delete body.mobileNumber;
  if (!activityId) delete body.activityId;
  
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_EXCEL_Download_ENDPOINT,
        body,
      ).then(async response => {
        if (response.statusCode === 1) {
          dispatch(handleFetchExcelData(response));
          toast.success(response.statusMessage);
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};

/** Method to fetch Activities Data */
export const getSurveyorData = (username: string, mobile?: any) => {
  const body: any = {
    mobile: mobile ?? "",
  };
  return async (dispatch: any) => {
    dispatch(handleStartLoading());
    try {
      await ServicesManagerAPICall.request(
        method.GET,
        paths.GET_SURVEYOR_ENDPOINT,
        body,
      ).then(async response => {
        console.log('response', response);
        if (response.statusCode === 1) {
          dispatch(handleSurveyorData(response));
        }
        else {
          dispatch(handleStopLoading());
          toast.error(response.statusMessage);
        }
      });
    } catch (error) {
      console.log('error', error);
      dispatch(handleError(error));
    }
  };
};
