import { saveInLocalStorage } from "../../utils/Util";
import CenterSpinner from "../../components/CenterSpinner";
import { useState } from "react";
import { Button } from "reactstrap";
import { STORAGE_KEY } from "../../utils/Constant";

interface ISettingPageProps {}

const SettingPage: React.FunctionComponent<ISettingPageProps> = (props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const callLogout = () => {
    saveInLocalStorage(STORAGE_KEY.SSOT, '');
  };
  return (
    <div className="d-flex justify-content-center wh-100">
      <div>
        <Button variant="primary" onClick={callLogout}>
          Logout
        </Button>
      </div>
      <CenterSpinner showLoader={showLoader} className="spinner-style" />
    </div>
  );
};

export default SettingPage;
