/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import axios, { AxiosRequestConfig } from "axios";
import { paths } from "./ServiceConstants";
import { getStorageDatum } from "./SettersAndGetters";
import { Constants, STORAGE_KEY } from "../utils/Constant";
import DebugUtils from "../utils/DebugUtils";
import { getFromLocalStorage } from "../utils/Util";
import { toast } from "react-toastify";

const ServicesManagerAPICall = {
  request: async (
    methodType: string,
    endpoint: string,
    data?: any
    // hasAuthToken: boolean = true
  ) => {
    let queryParams: string = "?";
    let url: any;
    let authenticationDatum: any;

    // if (hasAuthToken) {
    authenticationDatum = (await getFromLocalStorage(STORAGE_KEY.SSOT)) ?? {};
    // }

    const config: AxiosRequestConfig = {
      method: "",
      url: "",
      headers: {
        // Authorization: `Bearer ${authenticationDatum ?? ""}`,
        Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJnb3V0aGFtIiwiaWF0IjoxNzE4MTcxMzE4fQ.jjijzCy-J6hpktHkqpsWr2o6iI379yYjkLT01Vj6qko`,
        "Content-Type": "application/json",
      },
    };

    try {
      switch (methodType) {
        case "GET":
          {
            if (data) {
              for (const key in data) {
                queryParams += `${key}=${data[key]}&`;
              }
              url = `${paths.BASE_URL}${endpoint}${queryParams}`;
            } else {
              url = `${paths.BASE_URL}${endpoint}`;
            }
            config.method = `${methodType.toLowerCase()}`;
            config.url = url;
          }
          break;

        case "POST":
        case "PUT":
        case "PATCH":
          {
            config.method = `${methodType.toLowerCase()}`;
            config.url = `${paths.BASE_URL}${endpoint}`;
            config.data = data;
          }
          break;

        case "DELETE":
        case "OPTIONS":
          {
            config.method = `${methodType.toLowerCase()}`;
            config.url = `${paths.BASE_URL}${endpoint}`;
          }
          break;

        default:
          throw new Error(`Unsupported method type: ${methodType}`);
      }

      DebugUtils.printState(`config : ${JSON.stringify(config)}`);
      const response = await axios(config);
      DebugUtils.printState(`status : ${JSON.stringify(response.status)}`);
      if (response.status === 200) {
        DebugUtils.successState(
          `responseData : ${JSON.stringify(response.data)}`
        );
        return response.data ?? {};
      }
    } catch (error: any) {
      toast.error(error.message);
      DebugUtils.errorState(`error : ${error.message}`);
      //TODO
      // Alert.alert(
      //   'Error',
      //   'Unable to fetch the data at this point, Please try again.',
      //   [{text: 'OK', onPress: () => console.log('OK Pressed')}],
      // );
      return error.message;
    }
  },
};

export default ServicesManagerAPICall;
