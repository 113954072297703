import { createSlice } from "@reduxjs/toolkit";

interface FilterData {
    isLoading: boolean,
    error: boolean,
    id: number,
    value: string,
    storeIECSeasonsData: object,
    storeIECStatesData: object,
    storeIECDistrictsData: object,
    storeIECTehsilData: object,
    storeIECActivitiesData: object,
    storeIecInfoData: object,
    storeIecFormData: object,
    storeImageData: object,
    storeExcelData: object,
    storeSurveyorData: object,
    storeSavedFilterValues: object
}
const initialState: FilterData = {
    isLoading: false,
    error: false,
    id: 0,
    value: '',
    storeIECSeasonsData: {},
    storeIECStatesData: {},
    storeIECDistrictsData: {},
    storeIECTehsilData: {},
    storeIECActivitiesData: {},
    storeIecInfoData: {},
    storeIecFormData: {},
    storeImageData: {},
    storeExcelData: {},
    storeSurveyorData: {},
    storeSavedFilterValues:{
        username: '',
        stateId: '',
        districtId: '',
        blockId: '',
        seasonId: '', 
        year: '', 
        fromDate: '', 
        toDate: '', 
        mobileNumber: '', 
        activityId: ''
    }

}


export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        handleStartLoading(state) {
            state.isLoading = true;
        },
        handleStopLoading(state) {
            state.isLoading = false;
        },
        handleError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        handleFetchSeasonData(state, action) {
            state.isLoading = false;
            state.storeIECSeasonsData = action.payload;
        },
        handleFetchStatesData(state, action) {
            state.isLoading = false;
            state.storeIECStatesData = action.payload;
        },
        handleFetchDistrictsData(state, action) {
            state.isLoading = false;
            state.storeIECDistrictsData = action.payload;
        },
        handleFetchTehsilData(state, action) {
            state.isLoading = false;
            state.storeIECTehsilData = action.payload;
        },
        handleFetchActivitiesData(state, action) {
            state.isLoading = false;
            state.storeIECActivitiesData = action.payload;
        },
        handleFetchIecInfoData(state, action) {
            state.isLoading = false;
            state.storeIecInfoData = action.payload;
        },
        handleFetchIecFormData(state, action) {
            state.isLoading = false;
            state.storeIecFormData = action.payload;
        },
        handleFetchImageData(state, action) {
            state.isLoading = false;
            state.storeImageData = action.payload;
        },
        handleFetchExcelData(state, action) {
            state.isLoading = false;
            state.storeExcelData = action.payload;
        },
        handleSurveyorData(state, action) {
            state.isLoading = false;
            state.storeSurveyorData = action.payload;
        },
        handleStoreSavedFilterValues(state, action){
            state.isLoading = false;
            state.storeSavedFilterValues = action.payload;
        }
    },

});
export const filterSelector = (state: any) => state.filter;

export const { handleStartLoading, handleStopLoading, handleError, handleFetchSeasonData, handleFetchStatesData, handleFetchDistrictsData, handleFetchTehsilData, handleFetchActivitiesData, handleFetchIecInfoData, handleFetchIecFormData, handleFetchImageData, handleFetchExcelData, handleSurveyorData, handleStoreSavedFilterValues } = filterSlice.actions;

export default filterSlice.reducer