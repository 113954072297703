import { Constants } from "./Constant";

class DebugUtils {
  static printState(string: string): void {
    console.log(
      '\x1b[1m\x1b[33m%s\x1b[0m : \x1b[34m%s\x1b[0m',
      Constants.APP_NAME,
      string,
    );
  }

  static successState(string: string): void {
    console.log(
      '\x1b[1m\x1b[33m%s\x1b[0m : \x1b[32m%s\x1b[0m',
      Constants.APP_NAME,
      string,
    );
  }

  static errorState(string: string): void {
    console.log(
      '\x1b[1m\x1b[33m%s\x1b[0m : \x1b[31m%s\x1b[0m',
      Constants.APP_NAME,
      string,
    );
  }
}

export default DebugUtils;
