import * as React from 'react';

interface IHomePageProps {
}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
  return (
    <div className='wh-100'>Home Page</div>
  ) ;
};

export default HomePage;
