import { Navigate, Route, Routes } from "react-router-dom";
import IECActivities from "../screens/activities/iecActivities/IECActivities";
import HomePage from "../screens/home/HomePage";
import SettingPage from "../screens/setting/SettingPage";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={<IECActivities />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/setting" element={<SettingPage />} />
      <Route path="/iecActivities" element={<IECActivities />} />
    </Routes>
  );
};
