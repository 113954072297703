import { Container, Row, Col, Input } from "reactstrap"
import './Globals.css'
import { InputType } from "reactstrap/types/lib/Input";

/**Label and input props for IEC form details  */
interface IinputProps {
    placeHolder?: string;
    label?: string;
    icon?: React.ReactElement;
    value?: any;
    onChange?: (e: any) => void;
    numberOnly?: boolean;
    fixedLabel: any;
    type: InputType
}

/**LabelINput Component */
export const LabelInput = (props: IinputProps) => {
    return (
        <div>
            <Container>
                <Row style={{ marginBottom: '8px'}} >
                    <Col className="alignLabel">
                        {props.fixedLabel}
                    </Col>
                    <Col className="bg-light border-bottom" style={{ padding: 0, color: '#CFCACA' }}>
                        <Input disabled className="labelInput" type={props.type} style={{ border: "none", backgroundColor: "#F4F6F8", color: 'black' }} placeholder={props.placeHolder} label={props.label} icon={props.icon} value={props.value} onChange={props.onChange} numberonly={props.numberOnly} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}