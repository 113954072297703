const Label = ({ name }: any) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F4F6F8",
        border: "1px solid #D2D2D2",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "4px",
        fontWeight: 600,
        marginBottom: 15,
      }}
    >
      {" "}
      {name}{" "}
    </div>
  );
};

export default Label;
