import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import './Globals.css'
import { useSelector } from 'react-redux';


function ImageCarousels(args: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { storeImageData } = useSelector((state: any) => state.filter);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === storeImageData.data.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? storeImageData.data.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };


  const slides = storeImageData.data.map((item: any, index: number) => {
    return (
      <CarouselItem 
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}>
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <img style={{maxWidth: '60%', height: 'auto',  objectFit: "cover"}}
         src={"data:image/jpeg;base64," + item.base64} alt={item.altText} />
         </div>
         <div className="outerCountDiv"><button className="count">({ activeIndex + 1}/ {storeImageData.data.length})</button></div>
      </CarouselItem>
    );
  });

  return (
    <Carousel interval={null} style={{ position: 'static', display: "flex", justifyContent: 'space-around' }}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default ImageCarousels;