import { LoggedUserType } from "../models/GlobalModels";
import { API_RESPONSE_CODE, STORAGE_KEY } from "./Constant";

export const clearSession = () => {
  sessionStorage.clear(); //clear outlook detail
};

export const saveInSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value); //save In Session detail
};

export const getFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? data : "";
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const saveInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};


export const isAPIFailed = (fulfilled: any) => {
  if (
    fulfilled.responseInformation &&
    fulfilled.responseInformation &&
    fulfilled.responseInformation.responseCode === API_RESPONSE_CODE.FAIL
  ) {
    return true;
  } else {
    return false;
  }
};
export const isAPISuccess = (fulfilled: any) => {
  if (
    fulfilled.responseInformation &&
    fulfilled.responseInformation &&
    fulfilled.responseInformation.responseCode === API_RESPONSE_CODE.SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

export const getUser = (): LoggedUserType => {
    let user: LoggedUserType = {
      candidateId: 0,
      candidateUniqueId: "",
      employeeUniqueId: "",
      firstname: "",
      lastName: "",
      userActivationStatus: 0,
      userActivationStatusName: "",
      accessToken: "",
      accessExpiryTime: "",
      resetToken: "",
      resetExpiryTime: "",
      mobileNumber: 0,
      emailId: "",
      loginType: 0,
      loginTypeName: "",
      roleId: -1,
    };
    const data = localStorage.getItem(STORAGE_KEY.AUTH_DATA);
    if (data) {
      user = JSON.parse(data);
    }
    return user;
  };
