import { useEffect, useState } from "react";

import "./assets/style/App.css";
import "./assets/style/Colors.css";
import { ToastContainer, Zoom } from "react-toastify";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import Layout from "./layout/Layout";
import { checkAuthenticated } from "./redux/slices/loginSlice";
import { SIDE_MENU_STATUS, STORAGE_KEY } from "./utils/Constant";
import {
  updateDeviceStatus,
  updateSideMenuStatus,
} from "./redux/slices/dashboardSlice";
import { saveInLocalStorage, saveInSessionStorage } from "./utils/Util";
import useQuery from "./hooks/UseQueryHook";
function App() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  // dispatch(checkAuthenticated({}));
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    const isMobile = width <= 768;
    if (width <= 1000) {
      dispatch(updateSideMenuStatus(SIDE_MENU_STATUS.CLOSE));
    } else {
      dispatch(updateSideMenuStatus(SIDE_MENU_STATUS.OPEN));
    }
    dispatch(updateDeviceStatus({ isMobile }));
    console.log("innerWidthinnerWidth", window.innerWidth);
  }, [width]);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <div className="App">
      <Layout />
      <ToastContainer
        className="toast-style"
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Zoom}
        stacked={true}
      />
    </div>
  );
}

export default App;
