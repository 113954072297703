/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Col, Table } from "reactstrap";
import "./Globals.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ImageCarousels from "./DisplayImageCarsouels";
import { useSelector } from "react-redux";
import { CircleSpinnerOverlay } from "react-spinner-overlay";

export const ImageDownloadTable = ({ storeIecFormData }: any) => {
  const [showDialog, setShowDialog] = useState(false);
  const [state, setState] = useState({
    show: "",
    storeImage: "",
    indexValue: 0,
    base64Value: "",
    getImage: false,
    imagePath: "",
  });

  /** Method to open Single image Dialog */
  const handleClickOpen = (
    view: string,
    image: any,
    indexvalue: any,
    imagePath: any
  ) => {
    setState({
      ...state,
      show: view,
      storeImage: image,
      indexValue: indexvalue ?? 0,
      imagePath: imagePath,
    });
    setShowDialog(!showDialog);
  };

  /** Method to close Dialog */
  const handleCloseDialog = () => {
    setShowDialog(!showDialog);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { storeImageData, isLoading } = useSelector(
    (state: any) => state.filter
  );

  /** Method to open all images Dialog */
  const handleViewAll = (view: string) => {
    setState({ ...state, show: view });
    setShowDialog(!showDialog);
  };

  /** Method to download single image */
  const handleDownload = (base64String: any, imagePath?: any) => {
    setState({ ...state, base64Value: base64String, imagePath: imagePath });
    let a = document.createElement("a");
    a.href = `data:image/jpeg;base64,${base64String}`;
    a.download = `${imagePath}`;
    a.click();
    setState({ ...state, getImage: false });
  };

  /** Method to download all images */
  const handleDownloadAll = () => {
    storeImageData.data.forEach((base64String: any, index: number) => {
      setTimeout(() => {
        handleDownload(base64String.base64, state.imagePath);
      }, index * 2000);
    });
  };

  /** Method to download single image in image popup */
  const handleDownloadSingleImage = async () => {
    setState({ ...state, getImage: true });
  };

  useEffect(() => {
    if (state.getImage) {
      handleDownload(state.storeImage, state.imagePath);
    }
  }, [state]);

  return (
    <div style={{ marginTop: "15px" }}>
      <CircleSpinnerOverlay
        loading={isLoading}
        overlayColor="rgb(230, 255, 230, 0.2)"
        color="green"
      />
      <div>
       {!isLoading && <Table striped hover>
          <thead>
            <tr>
              <th
                style={{
                  width: "73%",
                  fontWeight: "700",
                  fontSize: "16px",
                  backgroundColor: "#F3F6FB",
                }}
                colSpan={1}
              >
                Filename({storeImageData.data && storeImageData.data.length})
              </th>
              <th style={{ backgroundColor: "#F3F6FB" }}></th>
              <th
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#537c5b",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#F3F6FB",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleViewAll("all")}
                >
                  View All /{" "}
                </div>
                <div style={{ cursor: "pointer" }} onClick={handleDownloadAll}>
                  {" "}
                  Download All
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {storeImageData.data?.map((value: any, index: number) => {
              return (
                <tr key={index}>
                  <td style={{ background: "#ECF1F4" }}>{value.imagePath}</td>
                  <td
                    style={{ cursor: "pointer", background: "#ECF1F4" }}
                    onClick={() =>
                      handleClickOpen(
                        "single",
                        value.base64,
                        index,
                        value.imagePath
                      )
                    }
                    className="imageTable"
                  >
                    View
                  </td>
                  <td
                    style={{ cursor: "pointer", background: "#ECF1F4" }}
                    onClick={() =>
                      handleDownload(value.base64, value.imagePath)
                    }
                    className="imageTable"
                  >
                    Download Attachment
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>}

        {showDialog && (
          <Dialog
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                justifyContent: "center",
                width: "36%",
              },
            }}
            fullScreen={fullScreen}
            open={showDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <div className="downloadButtonDev">
              {state.show === "single" ? (
                <button
                  className="downloadButton"
                  onClick={handleDownloadSingleImage}
                >
                  Download
                </button>
              ) : (
                <button className="downloadButton" onClick={handleDownloadAll}>
                  Download All
                </button>
              )}
            </div>
            <DialogContent className="dialogContent">
              <div>
                {state.show === "single" ? (
                  <div className="dialogContentText">
                    <img
                      style={{
                        maxWidth: "60%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      src={"data:image/jpeg;base64," + state.storeImage}
                      alt="Base64 Single"
                    />
                  </div>
                ) : (
                  <div className="dialogContentText">
                    <ImageCarousels />
                  </div>
                )}
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <div>
        <Col xs={8} style={{ marginBottom: "4px" }}>
          <Col className="alignLabel">Submitted By</Col>
          {storeIecFormData &&
            storeIecFormData.data &&
            storeIecFormData.data.length > 0 && (
              <Col style={{ padding: 0 }}>
                {storeIecFormData.data[0].submittedBy}
              </Col>
            )}
          <Col className="alignLabel">Mobile Number</Col>
          {storeIecFormData &&
            storeIecFormData.data &&
            storeIecFormData.data.length > 0 && (
              <Col style={{ padding: 0 }}>
                {storeIecFormData.data[0].mobileNumber}
              </Col>
            )}
          <Col className="alignLabel">Designation</Col>
          {storeIecFormData &&
            storeIecFormData.data &&
            storeIecFormData.data.length > 0 && (
              <Col style={{ padding: 0 }}>{storeIecFormData.data[0].role}</Col>
            )}
        </Col>
      </div>
    </div>
  );
};
