/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { IECFilterType } from "../../../models/IECActivitiesModel";
import { IecActivitiesFilter } from "./IECActivitiesSnippets";
import { IECActivitiesFormDetails } from "./IECActivitiesFormDetails";
import Label from "../../../components/Label";
import "./IECDetails.css";
import StickyIECHeadTable from "../../../components/IECTableComponenet";
import { useDispatch, useSelector } from "react-redux";
import { ImageDownloadTable } from "../../../components/ImageDownloadTable";
import { getExcelDownload, getIECInfoData, getSeasonsDataAPICall } from "../../api/iecActivityAPIs";
import IECPagination from "../../../components/IECPagenation";
import { handleIECPaginationDataSlice } from "../../../redux/slices/globalSlice";
import { handleFetchExcelData } from "../../../redux/slices/filterSlice";

/**Component is used to deal with IEC Activities*/
const IECActivities = () => {
  const dispatch: any = useDispatch();
  const { storeIecFormData, storeExcelData, storeSavedFilterValues, storeIecInfoData, storeImageData } = useSelector((state: any) => state.filter);
  const { updatePageStore, storeIECPaginationData } = useSelector((state: any) => state.global);


  useEffect(() => {
    dispatch(getSeasonsDataAPICall());
    dispatch(getIECInfoData('goutham', 25, 0));
  }, [dispatch])

  useEffect(() => {
    if (storeExcelData.statusCode === 1 && storeExcelData.data) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${storeExcelData.data}`;
      const downloadLink = document.createElement('a');
      const fileName = `${Date.now()}.xlsx`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      dispatch(handleFetchExcelData({}))
    }

  }, [storeExcelData.statusCode === 1])

  const downloadExcelWithFilters = () => {
    dispatch(getExcelDownload({ username: 'goutham', seasonId: storeSavedFilterValues.seasonId, stateId: storeSavedFilterValues.stateId, districtId: storeSavedFilterValues.districtId, blockId: storeSavedFilterValues.blockId, activityId: storeSavedFilterValues.activityId, fromDate: storeSavedFilterValues.fromDate, toDate: storeSavedFilterValues.toDate, year: storeSavedFilterValues.year, mobileNumber: storeSavedFilterValues.mobileNumber }));
  }

  /** Method to change pagination limit */
  const changePaginationLimit = (value: number) => {
    dispatch(
      handleIECPaginationDataSlice({
        ...storeIECPaginationData,
        offset: 0,
        limit: value,
        selectedLimit: value,
      })
    );
  };

  /** Method to go to previous page */
  const prevPage = (value: number) => {
    if (
      value >= storeIECPaginationData.selectedLimit &&
      storeIECPaginationData.offset > 0
    ) {
      dispatch(
        handleIECPaginationDataSlice({
          ...storeIECPaginationData,
          limit:
            storeIECPaginationData.limit -
              storeIECPaginationData.selectedLimit <
              25
              ? storeIECPaginationData.selectedLimit
              : storeIECPaginationData.limit -
              storeIECPaginationData.selectedLimit,
          offset:
            storeIECPaginationData.offset -
            storeIECPaginationData.selectedLimit,
        })
      );
    }
  };

  /** Method to go to next page */
  const nextPage = (value: number) => {
    if (value < storeIecInfoData.count) {
      dispatch(
        handleIECPaginationDataSlice({
          ...storeIECPaginationData,
          offset: storeIECPaginationData.limit,
          limit: value + storeIECPaginationData.selectedLimit,
        })
      );
    }
  };

  return (
    <>
      {!updatePageStore ? (
        <>
          <div className="formHeaderDiv">IEC Activities</div>
          <Label name="Apply Filters" />
          <IecActivitiesFilter onApplyFilters={function (iecFilterType: IECFilterType): void {
            throw new Error("Function not implemented.");
          }} />
          <div className="buttons-container">
            <p style={{ fontSize: "12px", color: "#537C5B", fontWeight: 600 }}>
              Total Records {storeIecInfoData.count === undefined ? 0 : `${(storeIECPaginationData.limit > storeIecInfoData.count) ? storeIecInfoData.count : storeIECPaginationData.limit}/${storeIecInfoData.count}`} 
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}

              onClick={() => downloadExcelWithFilters()}
            >
              <p className="excel-button">
                <img
                  src={require("../../../assets/img/Vector.svg").default}
                  className="excel-button-img"
                />
                Export XLS
              </p>
            </div>
          </div>
          <StickyIECHeadTable />
          <IECPagination storeIecInfoData={storeIecInfoData} storeIECPaginationData={storeIECPaginationData}
            changePaginationLimit={changePaginationLimit}
            nextPage={nextPage}
            prevPage={prevPage} />
        </>
      ) : (
        <div style={{ marginTop: "10px" }}>
          {storeIecFormData && <IECActivitiesFormDetails/>}
          {storeIecFormData && <ImageDownloadTable storeIecFormData={storeIecFormData} />}

        </div>
      )}
    </>
  );
};

export default IECActivities;
