/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import SearchBox from "../../../components/SearchBox";
import { DEFAULT_OPTION } from "../../../utils/Constant";
import { SelectBox } from "../../../components/SelectBox";
import KDatePicker from "../../../components/KDatePicker";
import moment from "moment";
import { IECFilterType, LocationDataType } from "../../../models/IECActivitiesModel";
import { useDispatch, useSelector } from "react-redux";
import { getActivityDataAPICall, getDistrictsDataAPICall, getIECInfoData, getSeasonsDataAPICall, getStatesDataAPICall, getSurveyorData, getTehsilDataAPICall } from "../../api/iecActivityAPIs";
import { FormUtils } from "../../../utils/FormUtils";
import { handleFetchDistrictsData, handleFetchTehsilData, handleStoreSavedFilterValues } from "../../../redux/slices/filterSlice";

interface iecFilterProps {
    filterRawData?: LocationDataType[];
    onApplyFilters: (iecFilterType: IECFilterType) => void;
}

export const IecActivitiesFilter = ({ filterRawData, }: iecFilterProps) => {
    const [iecFilter, setIecFilter] = useState<IECFilterType>({
        season: DEFAULT_OPTION,
        state: DEFAULT_OPTION,
        district: DEFAULT_OPTION,
        tehsil: DEFAULT_OPTION,
        activity: DEFAULT_OPTION,
        fromDate: undefined,
        toDate: undefined,
        financialYear: DEFAULT_OPTION,
        searchByName: DEFAULT_OPTION
    });

    const onResetFilter = () => {
        const resetFilter = {
            season: DEFAULT_OPTION,
            state: DEFAULT_OPTION,
            district: DEFAULT_OPTION,
            tehsil: DEFAULT_OPTION,
            activity: DEFAULT_OPTION,
            fromDate: undefined,
            toDate: undefined,
            financialYear: DEFAULT_OPTION,
            searchByName: DEFAULT_OPTION
        };

        setIecFilter(resetFilter);
        dispatch(getIECInfoData('goutham', 25, 0));
    };
    const { storeIECPaginationData } = useSelector((state: any) => state.global);

    const dispatch: any = useDispatch();
    useEffect(() => {
        dispatch(getSeasonsDataAPICall())
        dispatch(getStatesDataAPICall())
        dispatch(getActivityDataAPICall())
        handleApplyFilters()
        dispatch(getSurveyorData('goutham', iecFilter.searchByName.value));
    }, [dispatch, storeIECPaginationData])

    const { storeIECSeasonsData, storeIECStatesData, storeIECDistrictsData, storeIECTehsilData, storeIECActivitiesData, storeSurveyorData } = useSelector((state: any) => state.filter);


    const seasonsData = FormUtils.convertDataList(
        storeIECSeasonsData.data ?? []
    );

    const statesData = FormUtils.convertDataList(
        storeIECStatesData.data ?? []
    );

    const districtsData = FormUtils.convertDataList(
        storeIECDistrictsData.data ?? []
    );

    const tehsilData = FormUtils.convertDataList(
        storeIECTehsilData.data ?? []
    );

    const activitiesData = FormUtils.convertDataList(
        storeIECActivitiesData.data ?? []
    );
    const surveyorData = FormUtils.convertSearchDataList(
        storeSurveyorData.data ?? []
    );

    const yearsList: any = [
        {
            label: `${(new Date()).getFullYear()}-${(new Date()).getFullYear()+1}`,
            value: `${(new Date()).getFullYear()}-${(new Date()).getFullYear()+1}`,
        },
        {
            label: `${(new Date()).getFullYear() - 1}-${(new Date()).getFullYear()}`,
            value: `${(new Date()).getFullYear() - 1}-${(new Date()).getFullYear()}`,
        },

    ];

    const yearsListData = FormUtils.convertbleYearsList(
        yearsList ?? []
    );


    const filterForXlDownload = async (label: any, value: any) => {
        let filterValues = {
            ...iecFilter,
            username: 'goutham',
            activityId: iecFilter.activity.value,
            stateId: iecFilter.state.value,
            districtId: iecFilter.district.value,
            blockId: iecFilter.tehsil.value,
            seasonId: iecFilter.season.value,
            year: iecFilter.financialYear.value,
            fromDate: convert(iecFilter.fromDate),
            toDate: convert(iecFilter.toDate),
            mobileNumber: iecFilter.searchByName.value
        };

        switch (label) {
            case "season":
                filterValues.seasonId = value;
                break;
            case "state":
                filterValues.stateId = value;
                break;
            case "district":
                filterValues.districtId = value;
                break;
            case "tehsil":
                filterValues.blockId = value;
                break;
            case "activity":
                filterValues.activityId = value;
                break;
            case "fromDate":
                filterValues.fromDate = convert(value);
                break;
            case "toDate":
                filterValues.toDate = convert(value);
                break;
            case "financialYear":
                filterValues.year = value;
                break;
            case "mobileNumber":
                filterValues.mobileNumber = value;
                break;
            default:
                break;
        }
        await dispatch(handleStoreSavedFilterValues(filterValues));
    }




    /** Method to slice the date to yyyy-mm-dd fromat */
    function convert(str: any) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    /** Method to fetch Seasons data */
    const handleSeasonChange = async (value: any) => {
        const newFilter = {
            ...iecFilter,
            season: value !== null ? value : DEFAULT_OPTION,
            state: DEFAULT_OPTION,
            district: DEFAULT_OPTION,
            tehsil: DEFAULT_OPTION,
            activity: DEFAULT_OPTION
        };
        setIecFilter(newFilter);
        await filterForXlDownload('season', newFilter.season.value);
    }

    /** Method to fetch states data */
    const handleStateChange = async (value: any) => {
        if (value !== null) {
            const newFilter = {
                ...iecFilter,
                state: value !== null ? value : DEFAULT_OPTION,
                district: DEFAULT_OPTION,
                tehsil: DEFAULT_OPTION,
                activity: DEFAULT_OPTION
            };
            setIecFilter(newFilter);
            dispatch(getDistrictsDataAPICall("goutham", value.value));
            await filterForXlDownload('state', newFilter.state.value);
        }
        else {
            dispatch(handleFetchDistrictsData({}))
            setIecFilter({
                ...iecFilter,
                state: DEFAULT_OPTION,
                district: DEFAULT_OPTION,
                tehsil: DEFAULT_OPTION,
                activity: DEFAULT_OPTION
            })
        }

    }

    /** Method to fetch states data */
    const handleDistrictsChange = (value: any) => {
        if (value !== null) {
            const newFilter = {
                ...iecFilter,
                district: value !== null ? value : DEFAULT_OPTION,
                tehsil: DEFAULT_OPTION,
                activity: DEFAULT_OPTION
            }
            setIecFilter(newFilter)
            dispatch(
                getTehsilDataAPICall(
                    "goutham",
                    iecFilter.state.value,
                    value.value
                )
            );
            filterForXlDownload('district', newFilter.district.value);
        }
        else {
            dispatch(handleFetchTehsilData({}));
            setIecFilter({
                ...iecFilter,
                district: DEFAULT_OPTION,
                tehsil: DEFAULT_OPTION,
                activity: DEFAULT_OPTION
            })
        }
    }

    /** Method to fetch Tehsil data */
    const handleTehsilChange = (value: any) => {
        const newFilter = {
            ...iecFilter,
            tehsil: value !== null ? value : DEFAULT_OPTION,
            activity: DEFAULT_OPTION
        }
        setIecFilter(newFilter)
        filterForXlDownload('tehsil', newFilter.tehsil.value);
    }

    /** Method to fetch Activity data */
    const handleActivityChange = (value: any) => {
        const newFilter = {
            ...iecFilter,
            activity: value !== null ? value : DEFAULT_OPTION
        }
        setIecFilter(newFilter)
        filterForXlDownload('activity', newFilter.activity.value);
    }

    /** Method to update from date */
    const handleFromDateChange = (value: any) => {
        const newFilter = {
            ...iecFilter,
            fromDate: value !== null ? value : DEFAULT_OPTION,
            toDate : convert(new Date(value)) > convert(new Date(iecFilter.toDate)) ? undefined : iecFilter.toDate
        }
        setIecFilter(newFilter)
        filterForXlDownload('fromDate', value);
    }

    /** Method to update To date */
    const handleToDateChange = (value: any) => {
        const newFilter = {
            ...iecFilter,
            toDate: value !== null ? value : DEFAULT_OPTION,
        }
        setIecFilter(newFilter)
        filterForXlDownload('toDate', value);
    }

    /** Method to fetch Activity data */
    const handleYearChange = (value: any) => {
        const newFilter = {
            ...iecFilter,
            financialYear: value !== null ? value : DEFAULT_OPTION,
        }
        setIecFilter(newFilter)
        filterForXlDownload('financialYear', newFilter.financialYear.value);
    }

    /** Method to input field data */
    const handleSearchByName = (value: any) => {
        if (value !== null) {
            const newFilter = {
                ...iecFilter,
                searchByName: value !== null ? value : DEFAULT_OPTION,
            }
            setIecFilter(newFilter)
            filterForXlDownload('mobileNumber', newFilter.searchByName.value);
        }
        else {
            setIecFilter({
                ...iecFilter,
                searchByName: DEFAULT_OPTION,
            });
        }
    }

    /** Method to fetch data based on applied filters */
    const handleApplyFilters = async () => {
        setIecFilter({
            ...iecFilter,
        });
        await dispatch(getIECInfoData(
            'goutham',
            storeIECPaginationData.limit,
            storeIECPaginationData.offset,
            iecFilter.state.value,
            iecFilter.district.value,
            iecFilter.tehsil.value,
            iecFilter.season.value,
            iecFilter.financialYear.value,
            convert(iecFilter.fromDate),
            convert(iecFilter.toDate),
            iecFilter.searchByName.value,
            iecFilter.activity.value));
    }

    return (
        <div className="border rounded ps-3 pt-2 pe-3 pb-3">
            <Row>
                <Col xs={6} md={2} >
                    <SelectBox
                        label="Season"
                        options={seasonsData}
                        className="css-select-bg"
                        value={iecFilter.season}
                        onChange={handleSeasonChange}
                    />
                </Col>
                <Col xs={6} md={2}>
                    <SelectBox
                        label="State"
                        value={iecFilter.state}
                        className="css-select-bg"
                        options={statesData}
                        onChange={handleStateChange}
                    />
                </Col>
                <Col xs={6} md={2}>
                    <SelectBox
                        label="District"
                        options={districtsData}
                        className="css-select-bg"
                        value={iecFilter.district}
                        onChange={handleDistrictsChange}
                    />
                </Col>
                <Col xs={6} md={2}>
                    <SelectBox
                        label="Tehsil"
                        options={tehsilData}
                        className="css-select-bg"
                        value={iecFilter.tehsil}
                        onChange={handleTehsilChange}
                    />
                </Col>

            </Row>
            <Row>
                <Col xs={6} md={2}>
                    <SelectBox
                        label="Activity"
                        options={activitiesData}
                        className="css-select-bg"
                        value={iecFilter.activity}
                        onChange={handleActivityChange}
                    />
                </Col>
                <Col xs={6} md={2}>
                    <KDatePicker
                        label="From Date"
                        value={iecFilter.fromDate}
                        minDate={moment('01-01-2023').toDate()}
                        maxDate={new Date()}
                        onChange={handleFromDateChange}
                    />
                </Col>
                <Col xs={6} md={2}>
                    <KDatePicker
                        disabled={convert(iecFilter.fromDate) === 'NaN-aN-aN'}
                        label="To Date"
                        minDate={iecFilter.fromDate}
                        maxDate={new Date()}
                        value={iecFilter.toDate}
                        onChange={handleToDateChange}
                    />
                </Col>
                <Col xs={6} md={2}>
                    <SelectBox
                        label="Financial Year"
                        placeholder="Select Year"
                        options={yearsListData}
                        value={iecFilter.financialYear}
                        onChange={handleYearChange} />
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={3}>
                    <SelectBox
                        label="Search by Name / Mobile number"
                        placeholder="Search by Name or Mobile number"
                        value={iecFilter.searchByName}
                        options={surveyorData}
                        className="css-select-bg"
                        onChange={handleSearchByName}
                    />
                </Col>
                <Col xs={6} md={4}></Col>
                <Col xs={6} md={3} className="d-flex align-items-center pt-1 mt-3">
                    <Button
                        variant="primary"
                        className="cl-btn-primary"
                        onClick={handleApplyFilters}
                    >
                        Apply Filters
                    </Button>
                    <Button
                        variant="outline-primary"
                        className="mx-2 cl-btn-outline-primary"
                        onClick={() => {
                            onResetFilter();
                        }}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
