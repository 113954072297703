import { Table } from "reactstrap";
import './Globals.css'
import { useSelector } from "react-redux";
import TableComponent from "./Table";



const StickyIECHeadTable = () => {
    const { storeIecInfoData } = useSelector((state: any) => state.filter);
    const tableHeadersList = [
        "IEC ID",
        "State",
        "District",
        "Block",
        "Type of Activity",
        "Name",
        "Date",
        "",
    ]
    return (
        <div>
            <TableComponent tableHeadersList={tableHeadersList} data={storeIecInfoData} />
        </div>
    );
};

export default StickyIECHeadTable;
