type DataListType = {
    label: string | undefined;
    value: number | string | undefined;
  }[];
  
  type Response = {
    id?: number;
    value?: string;
  };

  type SearchResponse = {
    name?: string;
    mobile?: number;
  };
  type YearsResponse = {
    label?: number;
    value?: number;
  };
  
  export class FormUtils {
    static convertDataList(data: Response[]): DataListType {
      return data.map((item: Response) => ({
        label: item.value,
        value: item.id,
      }));
    }

    static convertSearchDataList(data: SearchResponse[]): DataListType {
      return data.map((item: SearchResponse) => ({
        label: `${item.mobile} :   ${item.name}`,
        value: item.mobile,
      }));
    }

    static convertbleYearsList(data: YearsResponse[]): DataListType {
      return data.map((item: YearsResponse) => ({
        label: `${item.label}`,
        value: `${item.value}`,
      }));
    }
  }
  
  export interface SelectOptionType {
    value: number | string;
    label: number | string;
  }