/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { LabelInput } from '../../../components/LabelInput';
import './IECDetails.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleUpdatePageSlice } from '../../../redux/slices/globalSlice';
import { getIECImagesAPICall } from '../../api/iecActivityAPIs';
import { handleFetchIecFormData } from '../../../redux/slices/filterSlice';


export const IECActivitiesFormDetails = () => {
    const [labelList, setLabelList] = useState<any[]>([]);
    const dispatch: any = useDispatch();
    const { storeIecFormData } = useSelector((state: any) => state.filter);

    useEffect(() => {
        if (storeIecFormData && storeIecFormData.data && storeIecFormData.data.length > 0) {
            const formData = storeIecFormData.data[0];
            dispatch(getIECImagesAPICall('goutham', formData.iecId, formData.imagePaths))
            const activity = formData.activityType;
            switch (activity) {
                case "Bank Visit":
                    {
                        setLabelList([
                            {
                                "IECID": formData.iecId,
                                "Season": formData.season,
                                "Select Year": formData.year,
                                "State": formData.stateName,
                                "District": formData.districtName,
                                "Tehsil / Block": formData.blockName,
                                "Revenue Circle": formData.level5Name,
                                "Gram Panchayat": formData.level6Name,
                                "Village Name": formData.villageName,
                                "Type of Activity": formData.activityType,
                                "Bank Name": formData.bankName,
                                "Branch Name": formData.branchName,
                                "Branch Manager Name": formData.branchManagerName,
                                "Branch Manager Phone NU": formData.branchManagerMobileNumber,
                                "Branch Email ID": formData.branchEmailId,
                                "Count of KCC A/C Holder": formData.countOfKccAccHolder,
                                "Total Expected Premium": formData.totalExpectedPremium,
                                "Comments": formData.comments
                            }
                        ]);
                    }
                    break;

                case "Market Campaign":
                    {
                        setLabelList([
                            {
                                "IECID": formData.iecId,
                                "Season": formData.season,
                                "Select Year": formData.year,
                                "State": formData.stateName,
                                "District": formData.districtName,
                                "Tehsil / Block": formData.blockName,
                                "Revenue Circle": formData.level5Name,
                                "Gram Panchayat": formData.level6Name,
                                "Village Name": formData.villageName,
                                "Type of Activity": formData.activityType,
                                "Market Campaign": formData.marketCampaign,
                                "Office Phone Number": formData.mobileNumber,
                                "Comments": formData.comments
                            }
                        ]);
                    }
                    break;

                case "Govt office visit":
                    {
                        setLabelList([
                            {
                                "IECID": formData.iecId,
                                "Season": formData.season,
                                "Select Year": formData.year,
                                "State": formData.stateName,
                                "District": formData.districtName,
                                "Tehsil / Block": formData.blockName,
                                "Revenue Circle": formData.level5Name,
                                "Gram Panchayat": formData.level6Name,
                                "Village Name": formData.villageName,
                                "Type of Activity": formData.activityType,
                                "Govt. Office": formData.govtOffice,
                                "Name of Officer": formData.officerName,
                                "Office Phone Number": formData.officerMobileNumber,
                                "Office Email ID": formData.officerEmailId,
                                "Comments": formData.comments
                            }
                        ]);
                    }
                    break;

                case "CSC Center Visit":
                    {
                        setLabelList([
                            {
                                "IECID": formData.iecId,
                                "Season": formData.season,
                                "Select Year": formData.year,
                                "State": formData.stateName,
                                "District": formData.districtName,
                                "Tehsil / Block": formData.blockName,
                                "Revenue Circle": formData.level5Name,
                                "Gram Panchayat": formData.level6Name,
                                "Village Name": formData.villageName,
                                "Type of Activity": formData.activityType,
                                "Center ID": formData.centerId,
                                "Name": formData.name,
                                "Phone Number": formData.phoneNumber,
                                "Center Email ID": formData.centerEmailId,
                                "Comments": formData.comments
                            }
                        ]);
                    }
                    break;
            }

        }
    }, [storeIecFormData, dispatch]);

    if (!labelList[0]) {
        return null;
    }

    return (
        <Row>
            <div className="formHeaderDiv">IEC Activities</div>
            <div className="formHeader">
                <img
                    onClick={() => {
                        dispatch(handleUpdatePageSlice(false));
                        dispatch(handleFetchIecFormData({}))
                        
                    }}
                    src={require('../../../assets/img/back-3.svg').default}
                    className="excel-button-img"
                    alt="Back"
                />
                View Form
            </div>
            {Object.keys(labelList[0]).map((value: string, index: number) => (
                <Col xs={5} key={`value-${index}`}>
                    <LabelInput 
                        fixedLabel={value}
                        placeHolder={labelList[0][value] ? labelList[0][value] : "N/A"}
                        type={value === 'Comments' || value.replace(/\\s/g, '') === "Market Campaign" ? 'textarea' : 'text'}
                    />
                </Col>
            ))}
        </Row>
    );
};
